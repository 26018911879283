import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { getContract } from '../contracts/contractInstance';

// Time formatting function
function formatTime(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;  // Format as "Xd Xh Xm"
}

function StakesDisplay() {
    const [stakes, setStakes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchStakes() {
            try {
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                if (!accounts.length) {
                    alert("Please connect to MetaMask.");
                    setLoading(false);
                    return;
                }
                const contract = await getContract();
                const stakesData = await contract.methods.getAllStakes(accounts[0]).call();

                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

                // Calculate lock status and time remaining for each stake
                const convertedStakes = stakesData.map(stake => {
                    const endTime = parseInt(stake.startTime) + parseInt(stake.lockUpPeriod);
                    const timeRemaining = Math.max(0, endTime - currentTime);
                    return {
                        ...stake,
                        amount: web3.utils.fromWei(stake.amount, 'ether'),  // Convert amount from Wei to ETCPOW
                        isLocked: currentTime < endTime,
                        timeRemaining: currentTime < endTime ? formatTime(timeRemaining) : 'Ready to Withdraw'
                    };
                });

                setStakes(convertedStakes);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching stakes:", error);
                setLoading(false);
            }
        }

        fetchStakes();
    }, []);

    if (loading) {
        return <div>Loading stakes...</div>;
    }

    return (
        <div>
            <h2 className="show-stakes-header">Your Stakes</h2>
            {stakes.length ? (
                <ul className="stake-list">
                    {stakes.map((stake, index) => (
                        <li key={index} className="stake-item">
                            Index: {stake.index}, Amount: {stake.amount} ETCPOW,
                            Start Time: {new Date(stake.startTime * 1000).toLocaleString()},
                            Lock-Up Period: {stake.lockUpPeriod} seconds,
                            Reward Rate: {stake.rewardRate}%, 
                            Status: {stake.isLocked ? `Locked (Time remaining: ${stake.timeRemaining})` : 'Ready to Withdraw'}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No active stakes found.</p>
            )}
        </div>
    );
}

export default StakesDisplay;





