import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import getWeb3 from '../getWeb3';
import tokenABI from '../contracts/CpowABI.json';
import stakingABI from '../contracts/MyContractABI.json';
import { BN } from 'web3-utils';

const tokenContractAddress = '0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83';
const stakingContractAddress = '0x18AE63116E56e454944307Fb74E24e96ca8A4cF0';
const apiUrl = "https://api.dexscreener.com/latest/dex/tokens/0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83";
const explorerUrl = "https://etc.blockscout.com/tx/";

function TotalStakedDisplay({ onClose }) {
    const [totalStaked, setTotalStaked] = useState('Loading...');
    const [totalValueLocked, setTotalValueLocked] = useState('Loading...');
    const [price, setPrice] = useState(null);
    const [events, setEvents] = useState([]);
    const web3Ref = useRef(null);
    const latestEventRef = useRef(null);

    useEffect(() => {
        getWeb3().then(web3 => {
            web3Ref.current = web3;
            const tokenContract = new web3.eth.Contract(tokenABI, tokenContractAddress);
            const stakingContract = new web3.eth.Contract(stakingABI, stakingContractAddress);
            initializeData(tokenContract, stakingContract);
        }).catch(console.error);
    }, []);

    const initializeData = async (tokenContract, stakingContract) => {
        try {
            const response = await axios.get(apiUrl);
            const currentPrice = parseFloat(response.data.pairs[0].priceUsd);
            setPrice(currentPrice);

            await refreshTotalStaked(tokenContract, currentPrice);

            const pastEvents = await stakingContract.getPastEvents('allEvents', { fromBlock: 0, toBlock: 'latest' });
            const formattedEvents = pastEvents.map(event => formatEvent(event));
            setEvents(formattedEvents.slice(-5).reverse());

            stakingContract.events.allEvents({ fromBlock: 'latest' })
                .on('data', event => {
                    setTimeout(() => {
                        const formattedEvent = formatEvent(event);
                        updateEvents(formattedEvent);
                        refreshTotalStaked(tokenContract, currentPrice);
                    }, 500); // Adding a 500ms delay
                });
        } catch (error) {
            console.error('Error initializing data', error);
        }
    };

    const refreshTotalStaked = async (tokenContract, price) => {
        try {
            const totalStakedWei = await tokenContract.methods.balanceOf(stakingContractAddress).call();
            const updatedStaked = web3Ref.current.utils.fromWei(new BN(totalStakedWei), 'ether');
            setTotalStaked(updatedStaked);
            setTotalValueLocked((updatedStaked * price).toFixed(2));
        } catch (error) {
            console.error('Error refreshing total staked', error);
        }
    };

    const formatEvent = (event) => {
        const transactionHash = event.transactionHash || event.id;

        return {
            id: transactionHash,
            user: event.returnValues.user,
            amount: web3Ref.current.utils.fromWei(new BN(event.returnValues.amount), 'ether'),
            action: event.event.toLowerCase(),
            link: explorerUrl + transactionHash
        };
    };

    const updateEvents = (newEvent) => {
        setEvents(prevEvents => {
            const existing = prevEvents.find(e => e.id === newEvent.id);
            if (!existing) {
                return [newEvent, ...prevEvents].slice(0, 5);
            }
            return prevEvents;
        });
        latestEventRef.current = newEvent.id;
    };

    return (
        <div className="total-staked-display">
            <button onClick={onClose} className="close-btn">X</button>
            <h4>Total ETCPOW Staked: {totalStaked} ETCPOW</h4>
            <h4>Total Value Locked: ${totalValueLocked} USD</h4>
            <div className="event-list">
                {events.map(event => (
                    <div key={event.id} className={`event-item ${event.id === latestEventRef.current ? 'flash' : ''}`}>
                        <p><a href={event.link} target="_blank" rel="noopener noreferrer" className="wallet-link">{event.user}</a> {event.action} {event.amount} ETCPOW</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TotalStakedDisplay;





























