// src/contracts/contractInstance.js
import getWeb3 from '../getWeb3'; // Make sure this imports a function that initializes web3
import MyContractABI from './MyContractABI.json';

let myContract = null;
const contractAddress = '0x18AE63116E56e454944307Fb74E24e96ca8A4cF0';

// Create a promise that resolves with the contract instance
const contractPromise = getWeb3().then(web3 => {
    myContract = new web3.eth.Contract(MyContractABI, contractAddress);
    return myContract;  // Ensure that the contract instance is returned
}).catch(error => {
    console.error("Failed to initialize the contract:", error);
    throw error;  // Re-throw the error to handle it in the consuming component
});

export function getContract() {
    return contractPromise;
}

