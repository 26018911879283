import React, { useState, useEffect } from 'react';
import getWeb3 from '../getWeb3';
import Swal from 'sweetalert2';
import { getContract } from '../contracts/contractInstance';
import Web3 from 'web3';

function WithdrawForm() {
    const [stakeIndex, setStakeIndex] = useState('');
    const [contract, setContract] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);
    const [stakes, setStakes] = useState([]);

    useEffect(() => {
        async function fetchContractAndStakes() {
            try {
                const contractInstance = await getContract();
                setContract(contractInstance);

                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                if (!accounts.length) {
                    Swal.fire('Error', 'Please connect to MetaMask.', 'error');
                    setLoading(false);
                    return;
                }

                const stakesData = await contractInstance.methods.getAllStakes(accounts[0]).call();
                const currentTime = Math.floor(Date.now() / 1000);

                const convertedStakes = stakesData.map((stake, index) => {
                    const endTime = parseInt(stake.startTime) + parseInt(stake.lockUpPeriod);
                    const timeRemaining = Math.max(0, endTime - currentTime);
                    return {
                        index,
                        amount: web3.utils.fromWei(stake.amount, 'ether'),
                        isLocked: currentTime < endTime,
                        timeRemaining: currentTime < endTime ? formatTime(timeRemaining) : 'Ready to Withdraw'
                    };
                });

                setStakes(convertedStakes);
                setLoading(false);
            } catch (error) {
                console.error("Error loading contract or stakes:", error);
                setLoading(false);
                Swal.fire('Error', 'Failed to load the contract or stakes. Please refresh the page.', 'error');
            }
        }

        fetchContractAndStakes();
    }, []);

    const handleWithdraw = async () => {
        if (!contract) {
            Swal.fire('Error', 'Contract is not loaded.', 'error');
            return;
        }
        if (!stakeIndex.trim()) {
            Swal.fire('Error', 'Please select a stake to withdraw.', 'error');
            return;
        }
        try {
            setIsTransactionLoading(true);
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            await contract.methods.withdraw(stakeIndex).send({ from: accounts[0] });
            setIsTransactionLoading(false);
            Swal.fire('Success', 'Withdrawal successful!', 'success');
        } catch (error) {
            setIsTransactionLoading(false);
            Swal.fire('Error', 'Withdrawal failed: ' + error.message, 'error');
        }
    };

    if (loading) {
        return <div>Loading contract and stakes...</div>;
    }

    return (
        <div>
            <h2 className="withdraw-header">4. Withdraw Your ETCPOW Tokens</h2>
            <select
                value={stakeIndex}
                onChange={e => setStakeIndex(e.target.value)}
                className="stake-dropdown"
            >
                <option value="" disabled>Select a stake</option>
                {stakes.length > 0 ? (
                    stakes.map((stake, index) => (
                        <option key={index} value={stake.index}>
                            {`Index: ${stake.index}, Amount: ${stake.amount} ETCPOW, Status: ${stake.isLocked ? `Locked (Time remaining: ${stake.timeRemaining})` : 'Ready to Withdraw'}`}
                        </option>
                    ))
                ) : (
                    <option value="" disabled>No stakes available</option>
                )}
            </select>
            <button onClick={handleWithdraw} disabled={isTransactionLoading} className="withdraw-button">
                {isTransactionLoading ? 'Withdrawing...' : 'Withdraw'}
            </button>
        </div>
    );
}

// Time formatting function
function formatTime(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;  // Format as "Xd Xh Xm"
}

export default WithdrawForm;



