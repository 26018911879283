import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Swal from 'sweetalert2';
import TokenApproval from './components/TokenApproval';
import StakeForm from './components/StakeForm';
import StakesDisplay from './components/StakesDisplay';
import WithdrawForm from './components/WithdrawForm';
import TotalStakedDisplay from './components/TotalStakedDisplay';
import getWeb3 from './getWeb3';

function App() {
  const [showStakes, setShowStakes] = useState(false);
  const [showTotalStaked, setShowTotalStaked] = useState(true);
  const [totalStaked, setTotalStaked] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);

  useEffect(() => {
    async function checkWalletConnection() {
      try {
        const web3Instance = await getWeb3();
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        setWalletConnected(accounts && accounts.length > 0);
        window.ethereum.on('accountsChanged', (newAccounts) => {
          setWalletConnected(newAccounts && newAccounts.length > 0);
        });
      } catch (error) {
        console.error('Error checking wallet connection:', error);
      }
    }
    checkWalletConnection();
    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener('accountsChanged', () => {});
      }
    };
  }, []);

  const handleConnectWallet = useCallback(async () => {
    try {
      const web3Instance = await getWeb3();
      setWeb3(web3Instance);
      const accounts = await web3Instance.eth.getAccounts();
      if (accounts && accounts.length > 0) {
        setWalletConnected(true);
        await Swal.fire({
          icon: 'info',
          title: 'Wallet Status',
          text: 'Wallet is already connected!',
          confirmButtonText: 'OK'
        });
      } else {
        await web3Instance.eth.requestAccounts();
        setWalletConnected(true);
        await Swal.fire({
          icon: 'success',
          title: 'Connected',
          text: 'Wallet successfully connected!',
          confirmButtonText: 'OK'
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      await Swal.fire({
        icon: 'error',
        title: 'Connection Failed',
        text: 'Failed to connect wallet. Check console for details.',
        confirmButtonText: 'OK'
      });
    }
  }, []);

  const toggleStakesDisplay = () => {
    if (!walletConnected) {
      handleConnectWallet();
    } else {
      setShowStakes(prevState => !prevState);
    }
  };

  const handleCloseTotalStaked = () => {
    if (!walletConnected) {
      handleConnectWallet();
    } else {
      setShowTotalStaked(false);
    }
  };

  return (
    <div className="App">
      <nav className="top-nav">
        <a href="#!" className="nav-link">ETCPOW SAVINGS (Coming Soon)</a>
        <a href="https://etcmc-membership-mint.netlify.app/" className="nav-link">DAO MEMBERSHIP</a>
        <a href="https://www.etc-mc.com/#/swap" className="nav-link">DEX</a>
        <a href="https://etcmc.org/" className="nav-link">HOME</a>
        <button onClick={handleConnectWallet} className={walletConnected ? 'nav-link connected-button' : 'nav-link connect-button'}>
          {walletConnected ? 'WALLET CONNECTED' : 'CONNECT WALLET'}
        </button>
      </nav>
      <header className="App-header">
        <img src="/etcmcLogo1.png" alt="ETCMC Logo" className="etcmc-logo" />
        <img src="/etcpowLogo1.png" className="App-logo" alt="ETCPOW Logo" />
        <div className="app-description">
          <p>Manage your ETCPOW stakes efficiently and securely.</p>
        </div>
        <TokenApproval />
        <StakeForm />
        <h2 className="show-stakes-header">3. Show Your Stakes</h2>
        <button onClick={toggleStakesDisplay}>
          {showStakes ? 'Hide Stakes' : 'Show Stakes'}
        </button>
        {showStakes && <StakesDisplay />}
        <WithdrawForm />
        {showTotalStaked && <TotalStakedDisplay onClose={handleCloseTotalStaked} setTotalStaked={setTotalStaked} web3={web3} />}
      </header>
      <footer className="footer">
        <img src="/etcmcLogo1.png" alt="ETCMC Logo" className="footer-logo" />
        <p className="footer-text">© 2024 ETCMC</p>
      </footer>
    </div>
  );
}

export default App;




























