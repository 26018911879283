import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { getContract } from '../contracts/contractInstance';
import Swal from 'sweetalert2';

function StakeForm() {
    const [amount, setAmount] = useState('');
    const [lockUpPeriod, setLockUpPeriod] = useState('7776000');
    const [contract, setContract] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isTransactionLoading, setIsTransactionLoading] = useState(false);

    useEffect(() => {
        getContract()
            .then(contractInstance => {
                setContract(contractInstance);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error loading contract:", error);
                setLoading(false);
            });
    }, []);

    const handleStake = async () => {
        if (!contract) {
            Swal.fire('Error', 'Contract is not loaded.', 'error');
            return;
        }
        if (!amount) {
            Swal.fire('Error', 'Please enter the amount to stake.', 'error');
            return;
        }
        try {
            setIsTransactionLoading(true);
            const web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();
            const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
            await contract.methods.stake(amountInWei, lockUpPeriod).send({ from: accounts[0] });
            setIsTransactionLoading(false);
            Swal.fire(
                'Success', 
                'Your ETCPOW stake has been successfully completed.\n\nThank you for your participation. Your contribution is valuable to the network\'s security and stability', 
                'success'
            );
        } catch (error) {
            setIsTransactionLoading(false);
            Swal.fire('Error', 'Staking failed: ' + error.message, 'error');
        }
    };

    if (loading) {
        return <div>Loading contract...</div>;
    }

    return (
        <div>
            <h2 className="stake-header">2. Stake Your ETCPOW Tokens</h2>
            <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                placeholder="Amount to stake"
            />
            <select value={lockUpPeriod} onChange={e => setLockUpPeriod(e.target.value)}>
                <option value="7776000">3 months</option>
                <option value="15552000">6 months</option>
                <option value="31104000">12 months</option>
            </select>
            <button onClick={handleStake} disabled={isTransactionLoading}>
                {isTransactionLoading ? 'Staking...' : 'Stake'}
            </button>
        </div>
    );
}

export default StakeForm;











