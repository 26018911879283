import Web3 from 'web3';
import Swal from 'sweetalert2';

const getWeb3 = () =>
  new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      try {
        // Check the current account connection status
        const accounts = await web3.eth.getAccounts();
        resolve(web3); // Return web3 regardless of connection status
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Connection Error',
          text: 'Unable to connect wallet. Please try again later.',
          confirmButtonText: 'OK'
        });
        reject(error);
      }
    } else if (window.web3) {
      const web3 = new Web3(window.web3.currentProvider);
      resolve(web3);
    } else {
      const provider = new Web3.providers.HttpProvider('https://your-etc-node.com');
      const web3 = new Web3(provider);
      resolve(web3);
    }
  });

export default getWeb3;






