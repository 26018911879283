import React, { useState } from 'react';
import Web3 from 'web3';
import Swal from 'sweetalert2';
import CpowABI from '../contracts/CpowABI.json';

const stakingContractAddress = '0x18AE63116E56e454944307Fb74E24e96ca8A4cF0'; // Staking contract address

const TokenApproval = () => {
    const [isApproving, setIsApproving] = useState(false); // State to track approval process

    const handleApprove = async () => {
        setIsApproving(true); // Start the approval process and show feedback
        try {
            const web3 = new Web3(window.ethereum);
            const accounts = await web3.eth.getAccounts();
            if (!accounts.length) {
                Swal.fire('Error', 'No accounts found. Please connect to MetaMask.', 'error');
                setIsApproving(false); // Reset the approving state
                return;
            }

            const tokenContract = new web3.eth.Contract(CpowABI, '0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83');
            const amountToApprove = web3.utils.toWei('1000', 'ether'); // Example: Approving 1000 tokens
            await tokenContract.methods.approve(stakingContractAddress, amountToApprove).send({ from: accounts[0] });
            Swal.fire('Success', 'Approval successful!', 'success');
        } catch (error) {
            console.error('Error approving tokens:', error);
            Swal.fire('Error', 'Approval failed: ' + error.message, 'error');
        } finally {
            setIsApproving(false); // Reset the approving state regardless of the outcome
        }
    };

    return (
        <div>
            <h4 className="approval-header">1. Approve Tokens for Staking</h4>
            <button onClick={handleApprove} className="approve-button" disabled={isApproving}>
                {isApproving ? 'Approving...' : 'Approve ETCPOW'}
            </button>
        </div>
    );
};

export default TokenApproval;



